<!--Nyelv választó-->
<div novalidate [formGroup]="screenFormGroup">
  <div style="display: flex; flex-direction: column" magic="{{mgc.Form4}}">
    <div style="display: flex; flex-direction: row; height: 250px; overflow: auto;">
      <div [magic]="mgc.Table1" class="container_border" style="border: none; width: 100%;">

        <table style="width: 100%;">
          <th style="display: none;">
            <label>
            </label>
          </th>
          <th style="display: none;">
            <label>
            </label>
          </th>
          <tr>
            <td></td>
            <td>
              <button
              type="button"
              class="btn btn-primary btn-sm"
              magic="{{ mgc.b_EgyikSem }}"
              [matTooltip]="mg.getTitle(mgc.b_EgyikSem)"
              [disabled]="mg.isDisabled(mgc.b_EgyikSem)"
              id="pureSF1_zoom"
              style="background-color: #041C2B;
              border-color: #041C2B;
              color: #fff;
              padding: .25rem .5rem;
              font-size: .875rem;
              line-height: 1.5;
              border-radius: .2rem;"
              [style.visibility]="mg.getVisible(mgc.b_EgyikSem)"
              >
                Egyik sem
              </button>
            </td>
          </tr>
          <ng-container *ngFor="let row of task.Records.list">
            <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
              [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row"
              [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table1, row.rowId)}"
              [class.selected]="tableService.getSelectedRow() === row.rowId" (click)="tableService.selectRow(row.rowId)"
              [magicRow]="row?.rowId">
              <td>
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" style="display: flex; flex-direction: row">
                  <label [magic]="mgc.Megnevezes" [rowId]="row.rowId"
                    [style.color]="mg.isDisabled(mgc.Megnevezes, row.rowId)?'#e9ecef':''"
                    [style.visibility]="mg.getVisible(mgc.Megnevezes, row.rowId)"
                    [attr.disabled]="mg.isDisabled(mgc.Megnevezes, row.rowId)">
                    {{mg.getValue(mgc.Megnevezes, row.rowId)}}
                  </label>
                  <ng-template [ngIf]="mg.getMustInput(magic,row.rowId)">
                    <span class="spanMustInput">*</span>
                  </ng-template>
                </div>
              </td>
              <td>
                <div>
                  <mat-form-field style="width: 150px" [style.visibility]="mg.getVisible(mgc.selected, row.rowId)">
                    <mat-select [magic]="mgc.selected" [rowId]="row.rowId" [formControlName]="mgc.selected">
                      <mat-option *ngFor="let o of mg.getItemListValues(mgc.selected, row.rowId);" [value]="o.index">
                        {{o.displayValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mgError [magic]=mgc.selected [rowId]=row.rowId></mgError>
                  <!--
                  HIBA(magic nem lét code edit modeba) ::: {{mg.isRowInRowEditing(row)}}
                  <div *ngIf="mg.isRowInRowEditing(row)">
                    <mat-form-field [style.visibility]="mg.getVisible(mgc.selected, row.rowId)">
                        <mat-select
                            [magic]="mgc.selected"
                            [rowId]="row.rowId"
                            [formControlName]="mgc.selected"
                        >
                            <mat-option
                                *ngFor="let o of mg.getItemListValues(mgc.selected, row.rowId);"
                                [value]="o.index"
                            >
                                {{o.displayValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mgError [magic]=mgc.selected> </mgError>
                  </div>

                  <div *ngIf="!mg.isRowInRowEditing(row)">
                    <label
                        [magic]="mgc.selected"
                        [rowId]="row.rowId"
                        [style.visibility]="mg.getVisible(mgc.selected, row.rowId)"
                    >
                        {{ mg.getDisplayValue(mgc.selected, row.rowId) }}
                    </label>
                  </div>
                  -->
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
      <label magic="{{mgc.Label10485770}}" [style.visibility]="mg.getVisible(mgc.Label10485770)"
        [style.display]="mg.getVisible(mgc.Label10485770) == 'visible' ? 'block' : 'none'"
        class="lable_overflow mgFont1" [attr.disabled]="mg.isDisabled(mgc.Label10485770)">
        KODI
      </label>
      <label magic="{{mgc.Label10485772}}" [style.visibility]="mg.getVisible(mgc.Label10485772)"
        [style.display]="mg.getVisible(mgc.Label10485772) == 'visible' ? 'block' : 'none'"
        class="lable_overflow mgFont1" [attr.disabled]="mg.isDisabled(mgc.Label10485772)">
        KODA
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.KODI)"
        [style.display]="mg.getVisible(mgc.KODI) == 'visible' ? 'block' : 'none'">
        <div>
          <input matInput [magic]="mgc.KODI" [formControlName]="mgc.KODI" mgFormat>
          <mgError [magic]=mgc.KODI> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.KODA)"
        [style.display]="mg.getVisible(mgc.KODA) == 'visible' ? 'block' : 'none'">
        <div>
          <input matInput [magic]="mgc.KODA" [formControlName]="mgc.KODA" mgFormat>
          <mgError [magic]=mgc.KODA> </mgError>
        </div>
      </mat-form-field>

    </div>
    <div style="display: flex; flex-direction: row">
      <label magic="{{mgc.languagesI}}" [style.visibility]="mg.getVisible(mgc.languagesI)"
        [style.display]="mg.getVisible(mgc.languagesI) == 'visible' ? 'block' : 'none'">
        {{mg.getValue(mgc.languagesI) }}
      </label>
    </div>
    <div style="display: flex; flex-direction: row">
      <label magic="{{mgc.languagesA}}" [style.visibility]="mg.getVisible(mgc.languagesA)"
        [style.display]="mg.getVisible(mgc.languagesA) == 'visible' ? 'block' : 'none'">
        {{mg.getValue(mgc.languagesA) }}
      </label>
    </div>
    <div style="display: flex; flex-direction: row">
      <label magic="{{mgc.languages}}" [style.visibility]="mg.getVisible(mgc.languages)"
        [style.display]="mg.getVisible(mgc.languages) == 'visible' ? 'block' : 'none'">
        {{mg.getValue(mgc.languages) }}
      </label>
    </div>
  </div>
</div>
